import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { flowRight } from 'lodash';
import { EXPERIMENT_ADD_ACTIONS_MENU_BY_CTA } from '@wix/communities-forum-client-commons';
import withExperiment from '../../hoc/with-experiment';
import { connect } from '../../../common/components/runtime-context';
import withAuth from '../../hoc/with-auth';
import ActionButton from '../action-button';
import NotificationsLink from '../link/notifications-link';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { NotificationIcon } from '../icons/notification-icon';
import CurrentUserAvatarImage from '../../containers/current-user-avatar-image';
import { LogoutIcon } from '../icons/logout-icon';
import AvatarImage from '../avatar-image';
import { LoginIcon } from '../icons/login-icon';

class ProfileActionsAddon extends Component {
  handleGoToProfile = () => {
    const { navigateToProfile, currentUser, userEventsClickProfileLink } = this.props;

    userEventsClickProfileLink({ isOwnProfile: true });
    navigateToProfile({ memberId: currentUser.siteMemberId, memberSlug: currentUser.slug });
  };

  handleLogout = () => {
    const { buttonClicked, navigateToRoot, logoutMember } = this.props;
    navigateToRoot();
    buttonClicked({ action: 'logout' });
    logoutMember();
  };

  handleLogin = () => {
    const { buttonClicked, loginMember } = this.props;
    buttonClicked({ action: 'login' });
    loginMember();
  };

  render() {
    const { isAuthenticated, isNewActionsMenuEnabled, t, theme } = this.props;

    const notificationsTextColor = theme ? '' : 'forum-text-color';

    const profileButton = isAuthenticated ? (
      <ActionButton
        data-hook="private-profile-actions__profile"
        onClick={this.handleGoToProfile}
        theme={theme}
      >
        <CurrentUserAvatarImage type={AvatarImage.SMALL} />
        {t('breadcrumbs.profile')}
      </ActionButton>
    ) : null;
    const notificationsButton = isAuthenticated ? (
      <ActionButton dataHook="private-profile-actions__notifications" theme={theme}>
        <NotificationIcon />
        <NotificationsLink class={notificationsTextColor}>Notifications</NotificationsLink>
      </ActionButton>
    ) : null;
    const logoutButton =
      isAuthenticated && !isNewActionsMenuEnabled ? (
        <ActionButton
          data-hook="private-profile-actions__logout"
          onClick={this.handleLogout}
          theme={theme}
        >
          <LogoutIcon />
          {t('private-profile-actions.logout')}
        </ActionButton>
      ) : null;
    const loginButton = !isAuthenticated ? (
      <ActionButton
        data-hook="private-profile-actions__logout"
        onClick={this.handleLogin}
        theme={theme}
      >
        <LoginIcon />
        {t('private-profile-actions.login')}
      </ActionButton>
    ) : null;

    return (
      <React.Fragment>
        {profileButton}
        {notificationsButton}
        {logoutButton}
        {loginButton}
      </React.Fragment>
    );
  }
}

ProfileActionsAddon.propTypes = {
  isAuthenticated: PropTypes.bool,
  navigateToRoot: PropTypes.func,
  navigateToProfile: PropTypes.func,
  loginMember: PropTypes.func,
  theme: PropTypes.string,
};

const mapRuntimeToProps = (state, ownProps, actions) => {
  return {
    logoutMember: actions.logout,
    loginMember: actions.requestLogin,
    navigateToRoot: actions.navigateToRoot,
    navigateToProfile: actions.navigateToProfile,
    userEventsClickProfileLink: actions.userEventsClickProfileLink,
    buttonClicked: actions.buttonClicked,
  };
};

export default flowRight(
  connect(mapRuntimeToProps),
  withAuth,
  withTranslate,
  withExperiment({
    isNewActionsMenuEnabled: EXPERIMENT_ADD_ACTIONS_MENU_BY_CTA,
  }),
)(ProfileActionsAddon);
